<template>
  <section
    :class="isHover || firstIndexFocused ? 'overlay-section active' : ''"
  ></section>
  <section class="w-full h-full z-50 relative" v-click-outside="close">
    <div
      :class="
        isHover || firstIndexFocused
          ? 'shadow-xl bg-white rounded-xl relative min-h-[580px] pt-5'
          : 'relative'
      "
      @mouseleave="clearHover"
      ref="heightList"
    >
      <div class="hidden lg:grid grid-cols-12">
        <div class="col-start-1 col-end-4 pr-5">
          <div
            v-for="(item, idx) in categoriesList"
            :key="idx"
            @mouseover="hoverCategory(idx)"
            :class="
              (firstIndexFocused && idx === 0) || activeCategoryIndex === idx
                ? 'bg-secondary-25 border-l-2 border-secondary-400 rounded-r p-[10px] cursor-pointer'
                : 'hover:bg-secondary-25 border-l-2 border-white hover:border-secondary-400 p-[10px] rounded-r cursor-pointer'
            "
          >
            <p-catalog-category :item="item" />
          </div>
        </div>
        <div class="col-start-4 col-end-13">
          <p-catalog-category-children
            :categories="categoriesList"
            :isActive="firstIndexFocused && activeCategoryIndex === 0"
            :activeCategoryIndex="activeCategoryIndex"
          ></p-catalog-category-children>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
const props = defineProps({
  isActive: Boolean,
  categoriesList: Object,
});
const heightList = ref(null);
const activeCategoryIndex = ref(0);
const activeSubcategoryIndex = ref(null);
const isHover = ref(false);

const firstIndexFocused = computed(() => {
  if (isHover.value) {
    return false;
  } else if (props.isActive) {
    return true;
  } else return false;
});

function clearHover() {
  isHover.value = false;
  activeCategoryIndex.value = 0;
  activeSubcategoryIndex.value = null;
}

function hoverCategory(idx) {
  activeCategoryIndex.value = idx;
  isHover.value = true;
}
const emit = defineEmits(["close"]);

function close() {
  emit("close");
}
</script>

<style scoped>
.masonry-3-col {
  column-count: 3;
  column-gap: 1em;
}

.break-inside {
  break-inside: avoid;
}

.overlay-section.active::after {
  content: "";
  position: fixed;
  top: 164px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: auto;
  background: rgba(0, 0, 0, 0.1);
}
</style>
