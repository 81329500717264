<script setup>
const route = useRoute();
const { $get } = useAxios();

const searchValue = ref("");
searchValue.value = route.query["search"] ?? "";

const {
  data: resultSearch,
  pending: isPendingResultSearch,
  refresh: refreshResults,
} = await useAsyncData(
  `get products by searhValue - ${searchValue.value}`,
  () => $get(`/api/v1/product/get/pre-search?query=${searchValue.value}`),
  {
    default: () => [],
    watch: [searchValue],
  }
);

const refSearchInput = ref(false);

const drawerVisible = ref(false);

async function seachByText() {
  if (searchValue.value) {
    if (
      resultSearch.value &&
      resultSearch.value.products.length === 1 &&
      resultSearch.value.categories.length === 0
    ) {
      const itemId = resultSearch.value.products[0].public_id;
      addSearchHistory(searchValue.value, "product", itemId);
      await navigateTo(`/product/${itemId}`);
    } else if (
      resultSearch.value.products.length === 0 &&
      resultSearch.value.categories.length > 0
    ) {
      const itemId = resultSearch.value.categories[0].public_id;
      addSearchHistory(searchValue.value, "category", itemId);
      await navigateTo(`/catalog/${itemId}`);
    } else {
      addSearchHistory(searchValue.value, "all", "");
      await navigateTo(`/search?q=${searchValue.value}`);
    }
    // drawerVisible.value = false;
    closeDrawer();
  } else {
    return;
  }
}

function clearSearchInput() {
  searchValue.value = "";
}

const history = useCookie(COOKIE_KEYS.searchHistory);

if (!history.value) {
  history.value = [];
}

const searchHistory = computed(() => {
  return history.value.sort();
});

function removeSearchQuery(id) {
  const foundQueryIndex = history.value.findIndex((item) => item.id === id);
  if (foundQueryIndex != -1) {
    history.value.splice(foundQueryIndex, 1);
  }
}

function addSearchHistory(query, type, itemId) {
  const foundItemIndex = history.value.findIndex(
    (item) => item.query === query.trim()
  );
  if (foundItemIndex === -1) {
    if (history.value.length >= 7) {
      history.value.shift(); //limit 7
    }
    history.value.push({
      id: Date.now(),
      query: query.trim(),
      type: type,
      itemId: itemId,
    });
  } else {
    history.value[foundItemIndex].id = Date.now();
  }
}

async function searchByHistoryQuery(searchQuery) {
  if (searchQuery.type === "category") {
    await navigateTo({
      path: `/catalog/${searchQuery.itemId}`,
      external: true,
    });
  } else if (searchQuery.type === "product") {
    await navigateTo({
      path: `/product/${searchQuery.itemId}`,
      external: true,
    });
  } else {
    await navigateTo({ path: `/search?q=${searchQuery.query}`, force: true });
  }
  // drawerVisible.value = false;
  closeDrawer();
}

function clearSearchHistory() {
  history.value = [];
}

const regex = ref(null);

watch(searchValue, () => {
  regex.value = new RegExp(searchValue.value, "gi");
});

function highlightedName(val) {
  return val.replace(
    regex.value,
    (match) => `<span style="font-weight: 700">${match}</span>`
  );
}

async function searchByCategory(item) {
  await navigateTo(`/catalog/${item}`);
  closeDrawer();
}

async function searchByItem(item) {
  await navigateTo(`/product/${item}`);
  closeDrawer();
}

const closeDrawer = () => {
  setTimeout(() => {
    document.body.style.overflow = "auto";
  }, 2000);
  setTimeout(() => {
    drawerVisible.value = false;
  }, 3000);
};
</script>

<template>
  <p-input
    v-model="searchValue"
    rounded="lg"
    class="relative parent-loop max-w-250 !text-black w-full !border-0 shadow-xl z-30 !h-11 lg:h-13"
    @click="drawerVisible = true"
    style-input="simple"
    placeholder="Поиск"
    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0)"
    :class="{ 'opacity-0': drawerVisible }"
  >
    <template #suffix>
      <p-icon-loop
        class="absolute right-4 h-5 w-5 top-1/2 transform -translate-y-1/2"
      />
    </template>
  </p-input>
  <p-drawer
    :is-open="drawerVisible"
    :speed="100"
    :max-Width="`100%`"
    class="z-50"
  >
    <div class="w-full">
      <div
        class="h-auto py-2 w-full px-3 flex items-center flex-row space-x-2 bg-primary-400"
      >
        <p-input
          ref="refSearchInput"
          v-model="searchValue"
          rounded="lg"
          class="relative !h-11 parent-loop max-w-250 !text-black w-full pl-5 !border-0 shadow-xl z-50"
          style-input="simple"
          placeholder="Поиск"
          style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); z-index: 50"
          @focus="focus"
          @blur="blur"
          @keydown.enter="seachByText"
        >
          <template #prepend>
            <p-icon-loop-gray
              class="absolute h-4 w-4 left-3 top-1.5 transform translate-y-1/2"
            />
          </template>
          <template #suffix v-if="searchValue.length">
            <p-icon-close
              @click="clearSearchInput"
              class="absolute right-4 h-3 w-3 top-1/2 transform -translate-y-1/2"
            />
          </template>
        </p-input>
        <button
          class="text-base w-30 h-11 text-white cursor-pointer"
          @click="drawerVisible = false"
        >
          Отмена
        </button>
      </div>
      <div
        class="bg-white h-screen px-4 pt-5 pb-6"
        style="height: calc(100vh - 60px)"
      >
        <template v-if="searchValue.length > 0">
          <div v-if="resultSearch.categories.length > 0" class="mb-4">
            <p class="font-lato text-base pb-4 text-gray-600">Категории:</p>
            <ul class="text-base space-y-4 lg:space-y-3">
              <li v-for="(elem, index) in resultSearch.categories" :key="index">
                <div
                  class="flex flex-row space-x-3 cursor-pointer lowercase text-primary-400 items-center"
                  @click="
                    addSearchHistory(elem.name, 'category', elem.public_id);
                    searchByCategory(elem.public_id);
                  "
                >
                  <p-icon-loop-gray class="w-4 h-4 shrink-0"></p-icon-loop-gray>
                  <div
                    v-html="highlightedName(elem.name)"
                    class="h-5 line-clamp-1"
                  ></div>
                  <!-- <p class="line-clamp-1">{{ elem.name }}</p> -->
                </div>
              </li>
            </ul>
          </div>
          <div v-if="resultSearch.products.length > 0">
            <p class="font-lato text-base pb-4 text-gray-600">Товары:</p>
            <ul class="text-base space-y-4 lg:space-y-3">
              <li v-for="(elem, index) in resultSearch.products" :key="index">
                <div
                  class="flex flex-row space-x-3 lowercase text-primary-400 items-center"
                  @click="
                    addSearchHistory(elem.name, 'product', elem.public_id);
                    searchByItem(elem.public_id);
                  "
                >
                  <p-icon-loop-gray class="w-4 h-4 shrink-0"></p-icon-loop-gray>
                  <div
                    v-html="highlightedName(elem.name)"
                    class="h-5 line-clamp-1"
                  ></div>
                  <!-- <p class="line-clamp-1">{{ elem.name }}</p>  -->
                </div>
              </li>
              <li
                class="font-sans text-sans text-secondary-300"
                @click="seachByText"
              >
                Все результаты поиска
              </li>
            </ul>
          </div>
        </template>
        <template v-else-if="searchHistory.length">
          <div class="flex pb-5 flex-row space-x-2 justify-between">
            <p class="text-base text-[#a1a1a1]">История поиска</p>
            <button
              class="text-base text-[#a1a1a1]"
              @click="clearSearchHistory()"
            >
              Очистить историю
            </button>
          </div>
          <div class="flex flex-col">
            <div
              class="flex h-10 items-center block flex-row space-x-2"
              v-for="(item, index) in history"
            >
              <div
                @click="searchByHistoryQuery(item)"
                class="flex cursor-pointer h-10 items-center block flex-row space-x-2"
              >
                <p-icon-back-clock class="shrink-0"></p-icon-back-clock>
                <span class="flex-1 line-clamp-1 text-primary-400">{{
                  item.query
                }}</span>
              </div>

              <span class="w-10">
                <p-icon-close
                  class="w-3 cursor-pointer mx-auto h-auto"
                  @click="removeSearchQuery(item.id)"
                ></p-icon-close>
              </span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </p-drawer>
</template>
