<template>
  <section>
    <div class="bg-primary-400 w-full pt-14 pb-30 lg:block hidden">
      <div
        class="grid grid-cols-10 container w-full text-[#E4E4E4] text-base px-4 xl:px-0"
      >
        <ul class="grid place-content-start col-start-1 col-end-3">
          <li
            class="flex items-center gap-2 pb-6 text-lg font-semibold text-white"
          >
            <span>О компании</span>
          </li>
          <div class="">
            <ul class="grid gap-2">
              <li class="cursor-pointer" @click="selectCategory('О нас')">
                О нас
              </li>
              <nuxt-link to="/vacancies"> Вакансии </nuxt-link>
              <li
                class="cursor-pointer"
                @click="selectCategory('Юридическим лицам')"
              >
                Юридическим лицам
              </li>
            </ul>
          </div>
        </ul>
        <ul
          class="grid col-start-3 col-end-5 place-content-start bg-primary-400"
        >
          <nuxt-link
            class="flex items-center gap-2 pb-6 text-lg font-semibold text-white"
          >
            Покупателям
          </nuxt-link>
          <div class="">
            <ul class="grid gap-2">
              <li
                class="cursor-pointer"
                @click="selectCategory('Как сделать заказ')"
              >
                Как сделать заказ
              </li>
              <li class="cursor-pointer" @click="selectCategory('Доставка')">
                Доставка
              </li>
              <!-- <nuxt-link to="/loans"> Кредиты </nuxt-link> -->
              <li class="cursor-pointer" @click="selectCategory('Услуги')">
                Услуги
              </li>
            </ul>
          </div>
        </ul>
        <ul class="grid col-start-9 col-end-11 justify-self-end">
          <div class="justify-end">
            <div class="flex flex-row space-x-5 justify-end">
              <a href="tel:+79409578812" target="_blank">
                <p-icon-fill-phone class="parent-phone cursor-pointer" />
              </a>
              <a
                href="https://www.instagram.com/positive_group_"
                target="_blank"
              >
                <p-icon-instagram class="parent-instagram cursor-pointer" />
              </a>

              <!-- <p-icon-facebook class="parent-facebook cursor-pointer" />
              <p-icon-tiktok class="parent-tiktok cursor-pointer" /> -->
            </div>
            <div class="flex place-content-end">
              <a href="tel:+79409578812" target="_blank">
                <p class="pt-12">+7 (940) 957-88-12</p>
              </a>
            </div>
          </div>
        </ul>
      </div>
    </div>

    <p-mini-footer></p-mini-footer>
  </section>
</template>
<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

const selectCategory = (category) => {
  router.push({ path: "/information", query: { category } });
};
</script>
