<template>
  <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.429 4.683a7.52 7.52 0 110 10.634.625.625 0 01.884-.884 6.27 6.27 0 100-8.866L3.869 8.01h2.293a.625.625 0 110 1.25H2.36a.625.625 0 01-.625-.625V4.834a.625.625 0 111.25 0v2.292l2.443-2.443zm5.31 2.127c.345 0 .625.28.625.625v2.82l1.861.865a.625.625 0 01-.527 1.133l-2.222-1.032a.625.625 0 01-.362-.567v-3.22c0-.344.28-.624.625-.624z"
      fill="#AFAFAF"
    ></path>
  </svg>
</template>
