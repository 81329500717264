<template>
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23 7.51358C23 9.23189 22.3468 10.8823 21.1804 12.1032C18.4954 14.9145 15.8911 17.8459 13.1058 20.5552C12.4674 21.1672 11.4546 21.1449 10.8437 20.5052L2.81913 12.1032C0.393622 9.56356 0.393622 5.46359 2.81913 2.92397C5.26848 0.359388 9.25873 0.359388 11.7081 2.92397L11.9998 3.22936L12.2913 2.92415C13.4656 1.69389 15.065 1 16.7358 1C18.4066 1 20.0059 1.69382 21.1804 2.92397C22.3469 4.14495 23 5.7953 23 7.51358Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linejoin="round"
      class="parent-hover:fill-primary"
    />
  </svg>
</template>

<style scoped>
.parent-favorite:hover .parent-hover\:fill-primary {
  stroke: #FFBA8D;
}
</style>
