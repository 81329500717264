<script setup>
import { onClickOutside } from "@vueuse/core";
const route = useRoute();
const { $get } = useAxios();
const searchValue = ref("");
searchValue.value = route.query["search"] ?? "";

const isLoadSearchData = ref(true);

const {
  data: resultSearch,
  pending: isPendingResultSearch,
  refresh: refreshResults,
} = await useAsyncData(
  `get products by searhValue - ${searchValue.value}`,
  () => $get(`/api/v1/product/get/pre-search?query=${searchValue.value}`),
  {
    default: () => [],
    watch: [searchValue],
  }
);

const isSearch = ref(false);
const refSearchInput = ref(false);

onClickOutside(refSearchInput, () => {
  isSearch.value = false;
});

async function seachByText() {
  if (searchValue.value) {
    if (
      resultSearch.value &&
      resultSearch.value.products.length === 1 &&
      resultSearch.value.categories.length === 0
    ) {
      await navigateTo(`/product/${resultSearch.value.products[0].public_id}`);
    } else if (
      resultSearch.value.products.length === 0 &&
      resultSearch.value.categories.length > 0
    ) {
      await navigateTo(
        `/catalog/${resultSearch.value.categories[0].public_id}`
      );
    } else {
      await navigateTo(`/search?q=${searchValue.value}`);
    }
    isSearch.value = false;
  } else {
    return;
  }
}

function focus() {
  isSearch.value = true;
}

const regex = ref(null);
watch(searchValue, () => {
  regex.value = new RegExp(searchValue.value, "gi");
});

function highlightedName(val) {
  return val.replace(
    regex.value,
    (match) => `<span style="font-weight: 700">${match}</span>`
  );
}
</script>

<template>
  <p-input
    ref="refSearchInput"
    v-model="searchValue"
    rounded="lg"
    class="relative parent-loop max-w-250 !text-black w-full !border-0 shadow-xl z-20 h-13"
    style-input="simple"
    placeholder="Поиск"
    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0)"
    @focus="focus"
    @blur="blur"
    @keydown.enter="seachByText"
  >
    <template #suffix>
      <p-icon-loop
        class="absolute right-4 h-6 w-6 top-1/2 transform -translate-y-1/2"
      />
    </template>
    <template #popup class="w-full">
      <hr
        v-if="
          isSearch &&
          searchValue.length &&
          (resultSearch.categories.length > 0 ||
            resultSearch.products.length > 0)
        "
        class="bg-primary-25 absolute top-12 left-0 xl:left-0.5 rounded-xl border-none"
        style="z-index: 10; height: 1.5px; width: calc(100% - 34px)"
      />
      <div
        v-if="
          isSearch &&
          searchValue.length &&
          (resultSearch.categories.length > 0 ||
            resultSearch.products.length > 0)
        "
        class="absolute w-full h-auto pt-1 -left-4 px-4 pb-5 bg-white border-b border-x border-transparent rounded-b-md"
        style="box-shadow: 0px 30px 20px 0px #48507029; top: calc(88%)"
      >
        <div v-if="resultSearch.categories.length > 0" class="mt-3">
          <p class="font-lato text-base text-gray-600">Категории:</p>
          <ul class="text-base mt-3 space-y-4 lg:space-y-3">
            <li v-for="(elem, index) in resultSearch.categories" :key="index">
              <nuxt-link
                :to="`/catalog/${elem.public_id}`"
                class="flex flex-row space-x-3 lowercase text-primary-400 items-center"
              >
                <p-icon-loop-gray class="w-4 h-4 shrink-0"></p-icon-loop-gray>
                <div
                  v-html="highlightedName(elem.name)"
                  class="h-5 line-clamp-1"
                ></div>
                <!-- <p class="line-clamp-1">{{ elem.name }}</p> -->
              </nuxt-link>
            </li>
          </ul>
        </div>
        <div v-if="resultSearch.products.length > 0" class="mt-3">
          <p class="font-lato text-base text-gray-600">Товары:</p>
          <ul class="text-base mt-3 space-y-4 lg:space-y-3">
            <li v-for="(elem, index) in resultSearch.products" :key="index">
              <nuxt-link
                :to="`/product/${elem.public_id}`"
                class="flex flex-row space-x-3 lowercase text-primary-400 items-center"
              >
                <p-icon-loop-gray class="w-4 h-4 shrink-0"></p-icon-loop-gray>
                <!-- <p class="line-clamp-1">{{ elem.name }}</p> -->
                <div
                  v-html="highlightedName(elem.name)"
                  class="h-5 line-clamp-1"
                ></div>
              </nuxt-link>
            </li>
            <li
              class="font-sans text-sans text-secondary-300"
              @click="seachByText"
            >
              Все результаты поиска
            </li>
          </ul>
        </div>
      </div>
    </template>
  </p-input>
</template>
