<template>
    <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle :class="active ? 'svgColor' : ''" cx="10" cy="10" r="9" stroke="#656F9C" stroke-width="2" />
        <path :class="active ? 'svgColor' : ''" d="M10 9L10 15" stroke="#656F9C" stroke-width="2"
            stroke-linecap="round" />
        <circle :class="active ? 'svgColor' : ''" cx="10" cy="6" r="1" :fill="active ? '#FFA267' : '#656F9C'" stroke="#656F9C" />
    </svg>

</template>
<style scoped>
.svgColor {
    stroke: #FFA267;
}
</style>
<script>
export default {
    props: {
        active: {
            type: Boolean,
            default: false,
        },
    },
    setup() { },
};
</script>