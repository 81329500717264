<template>
  <svg
    width="25"
    height="22"
    viewBox="0 0 25 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.37484 3.2499H23.499L21.2491 15.6243H6.62474L4.37484 3.2499ZM4.37484 3.2499C4.18735 2.49993 3.2499 1 1 1"
      stroke="#656F9C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="parent-hover:fill-primary"
      :class="active ? 'svgColor' : ''"
    />
    <path
      d="M11.6065 19.0713C11.6065 20.1363 10.743 20.9998 9.67799 20.9998C8.61292 20.9998 7.74951 20.1363 7.74951 19.0713"
      stroke="#656F9C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="parent-hover:fill-primary"
      :class="active ? 'svgColor' : ''"
    />
    <path
      d="M19.3213 19.0713C19.3213 20.1363 18.4579 20.9998 17.3928 20.9998C16.3278 20.9998 15.4644 20.1363 15.4644 19.0713"
      stroke="#656F9C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="parent-hover:fill-primary"
      :class="active ? 'svgColor' : ''"
    />
  </svg>
</template>

<style scoped>
.svgColor {
  stroke: #FFA267;
}

</style>
<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  setup () {}
}
</script>
