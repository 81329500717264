<template>
  <svg
    width="25"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    
  >
    <path
      d="M14.0043 20.3333V13.8888C14.0043 13.5206 14.3027 13.2222 14.6709 13.2222H21.1154C21.4836 13.2222 21.782 13.5206 21.782 13.8888V20.3333C21.782 20.7015 21.4836 20.9999 21.1154 20.9999H14.6709C14.3027 20.9999 14.0043 20.7015 14.0043 20.3333Z"
      stroke="#656F9C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="parent-hover:fill-primary"
      :class="active ? 'svgColor' : ''"
    />
    <path
      d="M1.78198 20.3333V13.8888C1.78198 13.5206 2.08046 13.2222 2.44865 13.2222H8.89309C9.26128 13.2222 9.55976 13.5206 9.55976 13.8888V20.3333C9.55976 20.7015 9.26128 20.9999 8.89309 20.9999H2.44865C2.08046 20.9999 1.78198 20.7015 1.78198 20.3333Z"
      stroke="#656F9C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="parent-hover:fill-primary"
      :class="active ? 'svgColor' : ''"
    />
    <path
      d="M14.0043 8.11111V1.66667C14.0043 1.29848 14.3027 1 14.6709 1H21.1154C21.4836 1 21.782 1.29848 21.782 1.66667V8.11111C21.782 8.4793 21.4836 8.77778 21.1154 8.77778H14.6709C14.3027 8.77778 14.0043 8.4793 14.0043 8.11111Z"
      stroke="#656F9C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="parent-hover:fill-primary"
      :class="active ? 'svgColor' : ''"
    />
    <path
      d="M1.78198 8.11111V1.66667C1.78198 1.29848 2.08046 1 2.44865 1H8.89309C9.26128 1 9.55976 1.29848 9.55976 1.66667V8.11111C9.55976 8.4793 9.26128 8.77778 8.89309 8.77778H2.44865C2.08046 8.77778 1.78198 8.4793 1.78198 8.11111Z"
      stroke="#656F9C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="parent-hover:fill-primary"
      :class="active ? 'svgColor' : ''"
    />
  </svg>
</template>

<style scoped>
.svgColor {
  stroke: #FFA267;
}

</style>
<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  setup () {}
}
</script>
