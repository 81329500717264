<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 15.8889V6.11111C0 2.73604 2.73604 0 6.11111 0H15.8889C19.2639 0 22 2.73604 22 6.11111V15.8889C22 19.2639 19.2639 22 15.8889 22H6.11111C2.73604 22 0 19.2639 0 15.8889Z"
      fill="white"
      class="parent-hover:fill-primary"
    />
    <path
      d="M11 16C13.7614 16 16 13.7614 16 11C16 8.23857 13.7614 6 11 6C8.23857 6 6 8.23857 6 11C6 13.7614 8.23857 16 11 16Z"
      stroke="#3A405A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17 4.01365L17.0125 4"
      stroke="#3A405A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>


<style scoped>
.parent-instagram:hover .parent-hover\:fill-primary {
  fill: #FFA267;
}
</style>
