<template>
    <div class="!h-14 lg:hidden block relative" style="z-index: 30">
      <div class="fixed left-0 bottom-0 w-full">
        <div class="">
          <div class="px-11 py-4 bg-white shadow-card">
            <div class="flex justify-between items-center">
              <nuxt-link to="/">
                <p-icon-home class="parent-home cursor-pointer" :active="routePath === '/'" />
              </nuxt-link>
              <nuxt-link to="/catalog">
                <p-icon-catalog class="parent-catalog cursor-pointer" :active="routePath === '/catalog'" />
              </nuxt-link>
              <!-- <nuxt-link to="/favorites">
                <div class="relative">
                  <p-icon-favorite-footer
                    class="parent-favorite cursor-pointer"
                    :active="routePath === '/favorites'"
                  />
                  <div
                    v-if="favoritesList.length"
                    class="absolute font-lato left-4 leading-3 -top-2 bg-secondary-300 flex items-center border-2 border-white text-white rounded-full min-w-5 h-5 font-medium px-1 justify-center"
                    style="font-size: 10px; z-index: 60"
                  >
                    {{ favoritesList.length }}
                  </div>
                </div>
              </nuxt-link> -->
              <nuxt-link to="/cart">
                <div class="relative">
                  <div v-if="cartLength"
                    class="absolute font-lato left-4 flex items-center leading-3 -top-2 bg-secondary-300 border-2 border-white text-white rounded-full min-w-5 h-5 font-medium px-1 justify-center"
                    style="font-size: 10px; z-index: 100">
                    {{ cartLength }}
                  </div>
                  <p-icon-cart-mobile class="parent-cart-mobile cursor-pointer" :active="routePath === '/cart'" />
                </div>
              </nuxt-link>
              <nuxt-link to="/information">
                <div class="relative">
                  <p-icon-information class="parent-information cursor-pointer"
                    :active="routePath === '/information'" />
                </div>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script setup>
const route = useRoute();
const routePath = computed(() => "/" + route.path.split("/")[1]);

const { length: cartLength } = useCart();

const favoritesList = computed(() => {
  const { favorites } = useFavoritesStore();
  return favorites;
});
</script>
